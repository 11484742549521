import { useState } from "react";

// this hook help save UI settings, the structure of settingKey is:
// page#section(tab/sub-page if exists)#component#propName
// used in all main pages for table pagination to save default pagination size
// can be used on any other UI setting that needs to be persisted
function useAppSettings(settingKey, initialValue) {
    const storageKey = "appSettings";

    const getStoredSettings = () => {
        try {
            const item = window.localStorage.getItem(storageKey);
            return item ? JSON.parse(item) : {};
        } catch (error) {
            console.error("Error reading localStorage key “" + storageKey + "”: ", error);
            return {};
        }
    };

    const [storedValue, setStoredValue] = useState(() => {
        const settings = getStoredSettings();
        return settings[settingKey] !== undefined ? settings[settingKey] : initialValue;
    });

    const setValue = (value) => {
        try {
            const settings = getStoredSettings();
            const valueToStore = value instanceof Function ? value(storedValue) : value;
            settings[settingKey] = valueToStore;
            setStoredValue(valueToStore);
            window.localStorage.setItem(storageKey, JSON.stringify(settings));
        } catch (error) {
            console.error("Error setting localStorage key “" + storageKey + "”: ", error);
        }
    };

    return [storedValue, setValue];
}

export default useAppSettings;
