import React, { lazy, Suspense, useContext } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig';
import Utils from "utils";
import AuthDataContext from "context/AuthDataContext";
import { RefreshProvider } from "context/RefreshContext";
import { NanoStateProvider } from "context/NanoStateContext";
import { AppDataProvider } from "context/AppDataContext";
import { ROUTES } from "configs/AppRoutesConfig";

const ACLRoute = (aclRouteProps) => {
  const { orgUserRole } = useContext(AuthDataContext);
  const {component: Component, ...rest} = aclRouteProps;
  const {path} = rest;
  const orgType = Utils.getOrganizationType();
  // check if path route is allowed by organization type
  const isPathAllowedByOrgType = Utils.pathAllowedByOrganizationType(path, orgType);
  // check if path route is allowed by user type
  const isPathAllowedByUserType = Utils.pathAllowedByUserType(path, orgUserRole);

  // if it is allowed by both then continue to the original path route
  if (isPathAllowedByOrgType && isPathAllowedByUserType) {
    return <Route {...aclRouteProps} />;
  }

  // This can be called for example for an NS_ONLY user in a management organization, it has no valid page
  // this is to avoid inifinite loop
  const defaultHomePage = Utils.getDefaultHomePage(orgUserRole);
  if (path === defaultHomePage) {
    console.log("Warning: current user default homepage is conflicting with routes that are not allowed for this organization type");
    return <Route {...aclRouteProps} />;
  }

  // redirect to root for default homepage redirect
  return <Redirect to={defaultHomePage} />
}

export const AppViews = ({defaultHomePage}) => {
  // in this component we should not use anything that causes rerender such as useContext etc
  // if the url is changing with query params it will rerender the whole (current) page

  return (
    <AppDataProvider>
      <NanoStateProvider>
        <RefreshProvider>
          <Suspense fallback={<Loading cover="content" />}>
            <Switch>
              <ACLRoute path={ROUTES.DASHBOARD} component={lazy(() => import(`./dashboard/dashboardRedirect`))} />
              <ACLRoute path={ROUTES.AWS_DASHBOARD} component={lazy(() => import(`./dashboard`))} />
              <ACLRoute path={ROUTES.AZURE_DASHBOARD} component={lazy(() => import(`./dashboard/azure`))} />
              <ACLRoute path={ROUTES.IAC_IMPORT} component={lazy(() => import(`./iacImport`))} />
              <ACLRoute path={ROUTES.RESOURCE_FINDER} component={lazy(() => import(`./resourceFinder`))} />
              <ACLRoute path={ROUTES.TF_EXPLORER} component={lazy(() => import(`./TfExplorer`))} />
              <ACLRoute path={ROUTES.TEMPLATES} component={lazy(() => import(`./templates/templateList`))} />
              <ACLRoute path={ROUTES.TEMPLATE} component={lazy(() => import(`./templates/templateDetails`))} />
              <ACLRoute path={ROUTES.DRIFT_CENTER} component={lazy(() => import(`./driftCenter`))} />
              <ACLRoute path={ROUTES.CONTROL_POLICIES} component={lazy(() => import(`./controlPolicies`))} />
              <ACLRoute path={ROUTES.SECURITY_STANDARD} component={lazy(() => import(`./controlPolicies/securityStandardDetails`))} />
              <ACLRoute path={ROUTES.CONTROL_POLICY_GROUP} component={lazy(() => import(`./controlPolicies/controlPolicyGroupDetails`))} />
              <ACLRoute path={ROUTES.CREATE_CONTROL_POLICY_GROUP_WIZARD} component={lazy(() => import(`./controlPolicies/createControlPolicyGroupWizard`))} />
              <ACLRoute path={ROUTES.EDIT_CONTROL_POLICY_GROUP_WIZARD} component={lazy(() => import(`./controlPolicies/editControlPolicyGroupWizard`))} />
              <ACLRoute path={ROUTES.POLICY_VIOLATIONS} component={lazy(() => import(`./policyViolations`))} />
              <ACLRoute path={ROUTES.STACK} component={lazy(() => import(`./stacks/stackDetails`))} />
              <ACLRoute path={ROUTES.DEPLOYMENT} component={lazy(() => import(`./stacks/deployments/deploymentDetails`))} />
              <ACLRoute path={ROUTES.PLAN} component={lazy(() => import(`./stacks/plans/planDetails`))} />
              <ACLRoute path={ROUTES.TASK} component={lazy(() => import(`./stacks/tasks/taskDetails`))} />
              <ACLRoute path={ROUTES.REMOTE_RUN} component={lazy(() => import(`./remoteRun`))} />
              <ACLRoute path={ROUTES.STACKS} component={lazy(() => import(`./stacks/stackList`))} />
              <ACLRoute path={ROUTES.CREATE_TEMPLATE_WIZARD} component={lazy(() => import(`./templates/createTemplateWizard`))} />
              <ACLRoute path={ROUTES.EDIT_TEMPLATE_WIZARD} component={lazy(() => import(`./templates/editTemplateWizard`))} />
              <ACLRoute path={ROUTES.CREATE_BLUEPRINT_WIZARD} component={lazy(() => import(`./templates/createBlueprintWizard`))} />
              <ACLRoute path={ROUTES.EDIT_BLUEPRINT_WIZARD} component={lazy(() => import(`./templates/editBlueprintWizard`))} />
              <ACLRoute path={ROUTES.CREATE_STACK_FROM_TEMPLATE_WIZARD} component={lazy(() => import(`./stacks/createStackFromTemplateWizard`))} />
              <ACLRoute path={ROUTES.CREATE_STACK_FROM_BLUEPRINT_WIZARD} component={lazy(() => import(`./stacks/createStackFromBlueprintWizard`))} />
              <ACLRoute path={ROUTES.CREATE_STACK_WIZARD} component={lazy(() => import(`./stacks/createStackWizard`))} />
              <ACLRoute path={ROUTES.EDIT_STACK_WIZARD} component={lazy(() => import(`./stacks/editStackWizard`))} />
              <ACLRoute path={ROUTES.IMPORT_STACK_WIZARD} component={lazy(() => import(`./stacks/importStackWizard`))} />
              <ACLRoute path={ROUTES.IMPORT_TO_EXISTING_STACK_WIZARD} component={lazy(() => import(`./stacks/importToExistingStackWizard`))} />
              <ACLRoute path={ROUTES.IMPORT_BULK_STACKS_WIZARD} component={lazy(() => import(`./stacks/importBulkStacksWizard`))} />
              <ACLRoute path={ROUTES.CREATE_NAMESPACE_WIZARD} component={lazy(() => import(`./namespaces/createNamespaceWizard`))} />
              <ACLRoute path={ROUTES.EDIT_NAMESPACE_WIZARD} component={lazy(() => import(`./namespaces/editNamespaceWizard`))} />
              <ACLRoute path={ROUTES.NAMESPACES} component={lazy(() => import(`./namespaces/namespaceList`))} />
              <ACLRoute path={ROUTES.NAMESPACE} component={lazy(() => import(`./namespaces/namespaceDetails`))} />
              <ACLRoute path={ROUTES.USER} component={lazy(() => import(`./userSetting`))} />
              <ACLRoute path={ROUTES.TEAM} component={lazy(() => import(`./teamDetails`))} />
              <ACLRoute path={ROUTES.ORGANIZATION} component={lazy(() => import(`./organizationSetting`))} />
              <ACLRoute path={ROUTES.PERSONAL_ACCESS_TOKEN} component={lazy(() => import(`./personalAccessToken`))} />
              <ACLRoute path={ROUTES.CREATE_MODEL_WIZARD} component={lazy(() => import(`./iacImport/model/createModelWizard`))} />
              <ACLRoute path={ROUTES.AUDIT} component={lazy(() => import(`./audit`))} />
              <ACLRoute path={ROUTES.CREATE_CONTROL_POLICY_WIZARD} component={lazy(() => import(`./controlPolicies/createControlPolicyWizard`))} />
              <ACLRoute path={ROUTES.EDIT_CONTROL_POLICY_WIZARD} component={lazy(() => import(`./controlPolicies/editControlPolicyWizard`))} />
              <ACLRoute path={ROUTES.CLOUD_EVENTS} component={lazy(() => import(`./cloudEvents`))} />
              <ACLRoute path={ROUTES.ACTIVE_RUNS} component={lazy(() => import(`./activeRuns`))} />
              <ACLRoute path={ROUTES.DOCS_FALLBACK} component={lazy(() => import(`./docsFallback`))} />
              <ACLRoute path={ROUTES.REPORTS} component={lazy(() => import(`./reports`))} />
              <ACLRoute path={ROUTES.CREATE_STACK_SET_WIZARD} component={lazy(() => import(`./organizationSetting/cloudCreds/createStackSetWizard`))} />
              <ACLRoute path={ROUTES.POST_GITHUB_INSTALLATION} component={lazy(() => import(`./organizationSetting/PostGithubInstallation`))} />
              <Redirect from={`${APP_PREFIX_PATH}`} to={defaultHomePage} />
            </Switch>
          </Suspense>
        </RefreshProvider>
      </NanoStateProvider>
    </AppDataProvider>
  )
}

export default React.memo(AppViews);