import React, { useEffect, useState, useContext } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { onMobileNavToggle } from "redux/actions/Theme";
import { Menu, Grid, Tag, Tooltip } from "antd";
import { ClockCircleOutlined } from '@ant-design/icons';
import moment from "moment";
import AuthDataContext from "context/AuthDataContext";
import IntlMessage from "../util-components/IntlMessage";
import Icon from "../util-components/Icon";
import consoleBackendService from "services/ConsoleBackendService";
import utils from 'utils';
import navigationConfig from "configs/NavigationConfig";
import { NAV_TYPE_SIDE, LIGHT_MODE, DARK_MODE } from "constants/ThemeConstant";
import { ORGANIZATION_SETTINGS, PRICING_PLAN } from "constants/AppConstant";
import { ORGANIZATION_ALLOWED_ACL_ROUTES, USER_ALLOWED_ACL_ROUTES } from "configs/ACLConfig";

const { SubMenu } = Menu;
const { useBreakpoint } = Grid;

const setLocale = (isLocaleOn, localeKey) =>
  isLocaleOn ? <IntlMessage id={localeKey} /> : localeKey.toString();

const setDefaultOpen = (key) => {
  let keyList = [];
  let keyString = "";
  if (key) {
    const arr = key.split("-");
    for (let index = 0; index < arr.length; index++) {
      const elm = arr[index];
      index === 0 ? (keyString = elm) : (keyString = `${keyString}-${elm}`);
      keyList.push(keyString);
    }
  }
  return keyList;
};

const FreeTrialIndicator = ({ userOrg = {}, navCollapsed }) => {
  const [trialDaysDiff, setTrialDaysDiff] = useState(null);
  const { pricingPlan, freeTrialExpiration } = userOrg;

  useEffect(() => {
      if (pricingPlan && pricingPlan === PRICING_PLAN.FREE_TRIAL) {
          let expirationDate = moment(freeTrialExpiration);
          // check if the date is valid
          if (expirationDate.isValid()) {
              // reset days to 00:00:00 to substract days
              expirationDate = expirationDate.set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
              const currentDate = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
              const daysDiff = expirationDate.diff(currentDate, "days");
              setTrialDaysDiff(daysDiff);
          } else {
              console.log("User org expiration date is invalid");
          }
      }
  }, [pricingPlan, freeTrialExpiration]);

  if (trialDaysDiff === null) {
      // days are not set or we are not in trial plan, returns falsey value
      return null;
  }

  const trialExpired = trialDaysDiff === 0 || trialDaysDiff < 0;
  const daysExpired = trialDaysDiff > 13 ? 14 : trialDaysDiff;

  return (
      <div className="text-center mb-4 ml-1">
          {trialExpired && (
              <Tag color="#f10" style={{ fontSize: "14px" }}>
                  {!navCollapsed && (
                      <span>
                          <ClockCircleOutlined className="mr-2" />
                          Free trial has ended
                      </span>
                  )}
                  {navCollapsed && (
                      <Tooltip placement="topLeft" title="Free trial has ended">
                          <ClockCircleOutlined />
                      </Tooltip>
                  )}
              </Tag>
          )}
          {!trialExpired && (
              <Tag color="green" style={{ fontSize: "14px" }}>
                  {!navCollapsed && (
                      <span>
                          Free trial expires in: {daysExpired} {daysExpired === 1 ? "day" : "days"}
                      </span>
                  )}
                  {navCollapsed && (
                      <span>
                          <ClockCircleOutlined className="mr-2" />
                          {daysExpired}
                      </span>
                  )}
              </Tag>
          )}
      </div>
  );
};

// Get navigation config types functions are to check which routes are allowed to be shown in the side main menu
// By organization type
const getNavigationConfigByOrganizationType = (navigationConfig) => {
  const orgType = utils.getOrganizationType();
  // get allowed routes by organization type, if by any chance it was not found use AWS
  const allowedRoutes = ORGANIZATION_ALLOWED_ACL_ROUTES?.[orgType] || ORGANIZATION_ALLOWED_ACL_ROUTES[ORGANIZATION_SETTINGS.TYPE.REGULAR];
  return navigationConfig.filter((page) => {
    const {path} = page;
    return allowedRoutes.includes(path);
  });
}

// By user type
const getNavigationConfigByUserType = (navigationConfig, orgUserRole) => {
  // get allowed routes by user type
  const allowedRoutes = USER_ALLOWED_ACL_ROUTES?.[orgUserRole];
  if (allowedRoutes && Array.isArray(allowedRoutes)) {
    // if user type is defined with ACL routes, only then apply the filtering, else return all navigationConfig
    return navigationConfig.filter((page) => {
      const {path} = page;
      return allowedRoutes.includes(path);
    });
  }
  return navigationConfig;
}

const SideNavContent = (props) => {
	const { routeInfo, hideGroupTitle, localization, onMobileNavToggle, navCollapsed, currentTheme } = props;
  const { orgUserRole } = useContext(AuthDataContext);
  const [userOrg, setUserOrg] = useState();
  const isMobile = !utils.getBreakPoint(useBreakpoint()).includes('lg');
	const closeMobileNav = () => {
		if (isMobile) {
			onMobileNavToggle(false)
		}
	}

  useEffect(() => {
    consoleBackendService
      .getCurrUserOrg()
      .then((res) => {
        const userOrgRes = res.response.items[0];
        setUserOrg(userOrgRes);
      });
  }, []);

  // filter first by organization type
  let filteredNavigationConfig = getNavigationConfigByOrganizationType(navigationConfig);
  // filter second by organization user type
  filteredNavigationConfig = getNavigationConfigByUserType(filteredNavigationConfig, orgUserRole);

  return (
    <div className="d-flex flex-column justify-content-between h-100">
      <Menu
        theme={currentTheme === LIGHT_MODE ? LIGHT_MODE : DARK_MODE}
        mode="inline"
        style={{ height: "100%", borderRight: 0 }}
        selectedKeys={[routeInfo?.key]}
        defaultOpenKeys={setDefaultOpen(routeInfo?.key)}
        className={hideGroupTitle ? "hide-group-title" : ""}
      >
        {filteredNavigationConfig.map((menu) =>
          menu.submenu.length > 0 ? (
            <Menu.ItemGroup
              key={menu.key}
              title={setLocale(localization, menu.title)}
            >
              {menu.submenu.map((subMenuFirst) =>
                subMenuFirst.submenu.length > 0 ? (
                  <SubMenu
                    icon={
                      subMenuFirst.icon ? (
                        <Icon type={subMenuFirst?.icon} />
                      ) : null
                    }
                    key={subMenuFirst.key}
                    title={setLocale(localization, subMenuFirst.title)}
                  >
                    {subMenuFirst.submenu.map((subMenuSecond) => (
                      <Menu.Item key={subMenuSecond.key}>
                        {subMenuSecond.icon ? (
                          <Icon type={subMenuSecond?.icon} />
                        ) : null}
                        <span>
                          {setLocale(localization, subMenuSecond.title)}
                        </span>
                        <Link onClick={() => closeMobileNav()} to={subMenuSecond.path} />
                      </Menu.Item>
                    ))}
                  </SubMenu>
                ) : (
                  <Menu.Item key={subMenuFirst.key}>
                    {subMenuFirst.icon ? <Icon type={subMenuFirst.icon} /> : null}
                    <span>{setLocale(localization, subMenuFirst.title)}</span>
                    <Link onClick={() => closeMobileNav()} to={subMenuFirst.path} />
                  </Menu.Item>
                )
              )}
            </Menu.ItemGroup>
          ) : (
            <Menu.Item key={menu.key}>
              {menu.icon ? <Icon type={menu?.icon} /> : null}
              <span>{setLocale(localization, menu?.title)}</span>
              {menu.path ? <Link onClick={() => closeMobileNav()} to={menu.path} /> : null}
            </Menu.Item>
          )
        )}
      </Menu>
      {userOrg && <FreeTrialIndicator userOrg={userOrg} navCollapsed={navCollapsed} />}
    </div>
  );
};

const TopNavContent = (props) => {
  const { topNavColor, localization } = props;
  return (
    <Menu mode="horizontal" style={{ backgroundColor: topNavColor }}>
      {navigationConfig.map((menu) =>
        menu.submenu.length > 0 ? (
          <SubMenu
            key={menu.key}
            popupClassName="top-nav-menu"
            title={
              <span>
                {menu.icon ? <Icon type={menu?.icon} /> : null}
                <span>{setLocale(localization, menu.title)}</span>
              </span>
            }
          >
            {menu.submenu.map((subMenuFirst) =>
              subMenuFirst.submenu.length > 0 ? (
                <SubMenu
                  key={subMenuFirst.key}
                  popupClassName="top-nav-menu"
                  icon={
                    subMenuFirst.icon ? (
                      <Icon type={subMenuFirst?.icon} />
                    ) : null
                  }
                  title={setLocale(localization, subMenuFirst.title)}
                >
                  {subMenuFirst.submenu.map((subMenuSecond) => (
                    <Menu.Item key={subMenuSecond.key}>
                      <span>
                        {setLocale(localization, subMenuSecond.title)}
                      </span>
                      <Link to={subMenuSecond.path} />
                    </Menu.Item>
                  ))}
                </SubMenu>
              ) : (
                <Menu.Item key={subMenuFirst.key}>
                  {subMenuFirst.icon ? (
                    <Icon type={subMenuFirst?.icon} />
                  ) : null}
                  <span>{setLocale(localization, subMenuFirst.title)}</span>
                  <Link to={subMenuFirst.path} />
                </Menu.Item>
              )
            )}
          </SubMenu>
        ) : (
          <Menu.Item key={menu.key}>
            {menu.icon ? <Icon type={menu?.icon} /> : null}
            <span>{setLocale(localization, menu?.title)}</span>
            {menu.path ? <Link to={menu.path} /> : null}
          </Menu.Item>
        )
      )}
    </Menu>
  );
};

const MenuContent = (props) => {
  return props.type === NAV_TYPE_SIDE ? (
    <SideNavContent {...props} />
  ) : (
    <TopNavContent {...props} />
  );
};

const mapStateToProps = ({ theme }) => {
  const { sideNavTheme, topNavColor } = theme;
  return { sideNavTheme, topNavColor };
};

export default connect(mapStateToProps, { onMobileNavToggle })(MenuContent);
